/* import __COLOCATED_TEMPLATE__ from './ios-platform.hbs'; */
/* RESPONSIBLE TEAM: team-messenger */
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import type {
  INSTALLATION_METHOD,
  PROGRAMMING_LANGUAGES,
} from 'embercom/components/installation-new/constants';

interface Signature {
  Element: HTMLElement;
  Args: {
    selectedBackendInstallationMethod: INSTALLATION_METHOD | PROGRAMMING_LANGUAGES;
    backendInstallationMethods: any[];
    setSelectedBackendInstallationMethod: (method: string) => void;
    onPingDomainsCountChange: (count: number) => void;
    onPingDomainsUnresolvedErrorCountChange: (count: number) => void;
  };
}

export default class IosPlatform extends Component<Signature> {
  @tracked selectedIosLanguage = 'swift';

  @action
  setSelectedIosLanguage(language: string) {
    this.selectedIosLanguage = language;
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'MessengerSettings::Security::IosPlatform': typeof IosPlatform;
  }
}
